"use client";

import { ApolloLink, HttpLink } from "@apollo/client";
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";

import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "app/actions";

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }
});

const makeClient = () => {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  });

  const link = ApolloLink.from([authLink, httpLink]);

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            clubRounds: {
              keyArgs: false,
              merge(existing, incoming) {
                if (existing) {
                  return {
                    ...incoming,
                    rounds: [...existing.rounds, ...incoming.rounds],
                  };
                }
                return incoming;
              },
            },
          },
        },
        RoundConnection: {
          fields: {
            rounds: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
        Round: {
          fields: {
            tickerItems: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
            settings: {
              keyArgs: false,
              merge(existing, incoming) {
                return {
                  ...existing,
                  ...incoming,
                };
              },
            },
          },
        },
        CoursePerformance: {
          fields: {
            rounds: {
              keyArgs: false,
              merge(_existing, incoming) {
                return incoming;
              },
            },
          },
        },
      },
    }),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            link,
          ])
        : link,
    uri: "https://countries.trevorblades.com",
  });
};
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
